import {
    AgeGroup,
    AudienceAnalysisChannelBenchmarkResponseDto,
    Gender,
} from '@api-clients/attention-data';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import {
    Box,
    Button,
    Card,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Icon,
    Spacer,
    Spinner,
    Switch,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useBoolean,
    VStack,
} from '@chakra-ui/react';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { useGetAllBenchmarkResultsForAudienceAnalysis } from '@hooks/strategyReport';
import { useCustomToast } from '@hooks/toast';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import {
    mapGendersToDatasets,
    mapGendersToLabels,
    mapAgeGroupsToLabels,
    mapAgeGroupsToDatasets,
    handleAudienceAnalysisSectionExport,
} from '@apps/attention360/pages/strategyReports/AudienceAnalysisUtil';
import { downloadBlob } from '@shared/utils';
import {
    ageGroupToLabel,
    numberFormatter,
} from '@apps/attention360/pages/strategyReports/StrategyReportUtil';
import { StrategyReportNavigation } from '@apps/attention360/atoms';
import { routing } from '@configs';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';
import { ConfigureAdFormatsButton } from './ConfigureAdFormatsButton';
import { ExportButton } from './ExportButton';
import { useStrategyReportContext } from './StrategyReportContextProvider';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const graphTabWidth = '10rem';

const defaultBarThickness = 25;

type AudienceAnalysisSectionProps = {};

export const AudienceAnalysisSection: FC<AudienceAnalysisSectionProps> = () => {
    const [isExporting, setIsExporting] = useBoolean();
    const {
        strategyReport,
        showOnlySelectedChannelsOrFormats,
        setShowOnlySelectedChannelsOrFormats,
    } = useStrategyReportContext();
    const [benchmarks, setBenchmarks] = useState<
        Array<AudienceAnalysisChannelBenchmarkResponseDto>
    >([]);
    const { errorToast } = useCustomToast();
    const [isLoadingBenchmarks, setIsLoadingBenchmarks] = useBoolean();
    const [audienceAnalysisChannelTabIndex, setAudienceAnalysisChannelTabIndex] = useState(0);
    const { getAllBenchmarkResultsForAudienceAnalysis } =
        useGetAllBenchmarkResultsForAudienceAnalysis();
    const getAudienceAnalysisBenchmarkResult = async () => {
        try {
            setIsLoadingBenchmarks.on();
            const benchmark = await getAllBenchmarkResultsForAudienceAnalysis({
                strategyReportId: strategyReport.strategyReportId!,
            });
            setBenchmarks(benchmark);
        } catch (e: any) {
            errorToast('Oops', 'Something went wrong');
        } finally {
            setIsLoadingBenchmarks.off();
        }
    };
    useEffect(() => {
        getAudienceAnalysisBenchmarkResult().catch();
    }, [strategyReport]);

    const emitViewAudienceAnalysisSelectionEvent = usePosthogEvent(
        'View strategy report audience analysis section',
    );
    useEffect(() => {
        emitViewAudienceAnalysisSelectionEvent();
    }, []);
    const displayedBenchmarks = useMemo(() => {
        const selectedCh = benchmarks.filter((b) => b.isSelected ?? false);
        const unselectedCh = benchmarks.filter((b) => !(b.isSelected ?? false));
        const sortedSelectedCh = selectedCh.sort(
            (a, b) => (b.averageActiveAttention ?? 0) - (a.averageActiveAttention ?? 0),
        );
        const sortedUnselectedCh = unselectedCh.sort(
            (a, b) => (b.averageActiveAttention ?? 0) - (a.averageActiveAttention ?? 0),
        );
        return sortedSelectedCh.concat(sortedUnselectedCh);
    }, [showOnlySelectedChannelsOrFormats, benchmarks, strategyReport]);
    const chartRef = useRef<ChartJSOrUndefined<'bar'>>();
    const onExport = async () => {
        try {
            setIsExporting.on();
            const blob = await handleAudienceAnalysisSectionExport(
                chartRef.current,
                displayedBenchmarks,
                audienceAnalysisChannelTabIndex,
            );
            downloadBlob(
                blob,
                `attentionPLAN_Audience-Analysis_${
                    displayedBenchmarks[audienceAnalysisChannelTabIndex].channelName ?? ''
                }_${strategyReport.name ?? ''}.png`,
            );
        } catch (e: any) {
            const errorMsg = e instanceof Error ? e.message : 'Something went wrong';
            errorToast('Export failed', errorMsg);
        } finally {
            setIsExporting.off();
        }
    };
    return (
        <Box position="relative">
            <Flex>
                <ContainerTitle
                    headingText="Audience analysis"
                    subtitleText="Here’s a breakdown of how a channel performs with each demographic group so you can target your audience more effectively"
                />
                <Spacer />
                <VStack align="end">
                    <HStack>
                        <ExportButton
                            onClick={() => onExport()}
                            isLoading={isExporting}
                            isDisabled={isExporting}
                        />
                    </HStack>
                    <Box>
                        <FormControl display="flex" alignItems="center">
                            <FormLabel mb="0">Show only selected formats</FormLabel>
                            <Switch
                                isChecked={showOnlySelectedChannelsOrFormats}
                                onChange={(e) => {
                                    setAudienceAnalysisChannelTabIndex(0);
                                    setShowOnlySelectedChannelsOrFormats(e.target.checked);
                                }}
                            />
                        </FormControl>
                    </Box>
                </VStack>
            </Flex>
            {/** Chart */}
            <Card minH="45rem" p="3rem" mt="1rem" position="relative">
                {displayedBenchmarks.length > 0 && (
                    <Box>
                        <Text
                            m={0}
                            pl=".75rem"
                            py={0}
                            pr={0}
                            width="9rem"
                            h="2.5rem"
                            textAlign="left"
                            fontSize=".875rem"
                            fontWeight={600}
                            color="gray.600"
                            textTransform="uppercase"
                            letterSpacing="0.0375rem"
                            border="1px solid"
                            borderColor="gray.100"
                            borderTop="none"
                            borderX="none"
                        >
                            Channel
                        </Text>
                    </Box>
                )}
                <Tabs
                    variant="unstyled"
                    m={0}
                    p={0}
                    display="flex"
                    gap="4rem"
                    tabIndex={audienceAnalysisChannelTabIndex}
                    onChange={(tabIndex) => {
                        setAudienceAnalysisChannelTabIndex(tabIndex);
                    }}
                >
                    <TabList width={graphTabWidth} display="block" p={0} m={0}>
                        {displayedBenchmarks.map((b, index) => (
                            <Tab
                                key={b.channelId ?? ''}
                                display="block"
                                m={0}
                                py={0}
                                pr={0}
                                pl=".75rem"
                                width="9rem"
                                h="3rem"
                                bg={index === audienceAnalysisChannelTabIndex ? 'gray.50' : 'white'}
                                fontSize=".875rem"
                                fontWeight={600}
                                color="gray.800"
                                textTransform="none"
                                border="1px solid"
                                borderColor="gray.100"
                                borderTop="none"
                                borderX="none"
                                letterSpacing="0"
                                textAlign="left"
                                position="relative"
                                opacity={
                                    !(b.isSelected ?? false) && showOnlySelectedChannelsOrFormats
                                        ? 0
                                        : 1
                                }
                                pointerEvents={
                                    !(b.isSelected ?? false) && showOnlySelectedChannelsOrFormats
                                        ? 'none'
                                        : 'auto'
                                }
                            >
                                <Flex alignItems="center" gap=".5rem">
                                    {b.isSelected && (
                                        <Box
                                            display="inline-block"
                                            w=".5rem"
                                            h=".5rem"
                                            borderRadius="50%"
                                            bg="green.500"
                                        />
                                    )}
                                    {b.channelName}
                                </Flex>
                                {index === audienceAnalysisChannelTabIndex && (
                                    <Box
                                        position="absolute"
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        width="3px"
                                        bg="green.400"
                                    />
                                )}
                            </Tab>
                        ))}
                    </TabList>
                    <TabPanels m={0} p={0}>
                        {displayedBenchmarks.map((b) => {
                            const gender = (b.byGender ?? []).filter(
                                (g) =>
                                    g.gender !== Gender.Other &&
                                    (g.averageActiveAttention ?? 0) +
                                        (g.averagePassiveAttention ?? 0) +
                                        (g.averageInactiveAttention ?? 0) >
                                        0,
                            );
                            const ageGroup = (b.byAgeGroup ?? []).filter(
                                (a) =>
                                    (a.averageActiveAttention ?? 0) +
                                        (a.averagePassiveAttention ?? 0) +
                                        (a.averageInactiveAttention ?? 0) >
                                    0,
                            );
                            const genderLabels = gender.map((g) => {
                                const label = (g.gender ?? '').toString();
                                return label.charAt(0).toUpperCase() + label.slice(1);
                            });
                            const genderGraphData: ChartData<'bar', number[], string> = {
                                labels: mapGendersToLabels(gender),
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                datasets: mapGendersToDatasets(
                                    gender,
                                    b.averageActiveAttention ?? 0,
                                    b.averagePassiveAttention ?? 0,
                                ),
                            };
                            const ageGroupLabels = ageGroup.map((g) => ageGroupToLabel(g.ageGroup));
                            const ageGroupGraphData: ChartData<'bar', number[], string> = {
                                labels: mapAgeGroupsToLabels(ageGroup),

                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                datasets: mapAgeGroupsToDatasets(
                                    ageGroup,
                                    b.averageActiveAttention ?? 0,
                                    b.averagePassiveAttention ?? 0,
                                ),
                            };
                            const genderGraphOptions: ChartOptions<'bar'> = {
                                animation: false,
                                plugins: {
                                    legend: {
                                        reverse: false,
                                    },
                                    tooltip: {
                                        enabled: true,
                                        callbacks: {
                                            title(tooltipItems): string | string[] {
                                                const item = tooltipItems.at(0);
                                                if (!item) return '';
                                                return genderLabels[item.dataIndex];
                                            },
                                            label(tooltipItem): string | string[] {
                                                const datasetObject =
                                                    genderGraphData.datasets[
                                                        tooltipItem.datasetIndex
                                                    ];
                                                const { label } = datasetObject;
                                                const value =
                                                    datasetObject.data[tooltipItem.dataIndex];
                                                return `${label}: ${numberFormatter.format(value)}`;
                                            },
                                        },
                                    },
                                },
                                responsive: true,
                                interaction: { mode: 'index' },
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true,
                                        title: {
                                            display: true,
                                            text: 'Seconds',
                                        },
                                    },
                                },
                            };
                            const ageGroupGraphOptions: ChartOptions<'bar'> = {
                                animation: false,
                                plugins: {
                                    legend: {
                                        reverse: false,
                                    },
                                    tooltip: {
                                        enabled: true,
                                        callbacks: {
                                            title: (tooltipItems): string | string[] => {
                                                const item = tooltipItems.at(0);
                                                if (!item) return '';
                                                return ageGroupLabels[item.dataIndex];
                                            },
                                            label: (tooltipItem): string | string[] => {
                                                const datasetObject =
                                                    ageGroupGraphData.datasets[
                                                        tooltipItem.datasetIndex
                                                    ];
                                                const { label } = datasetObject;
                                                const value =
                                                    datasetObject.data[tooltipItem.dataIndex];
                                                return `${label}: ${numberFormatter.format(value)}`;
                                            },
                                        },
                                    },
                                },
                                responsive: true,
                                interaction: { mode: 'index' },
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        title: {
                                            display: true,
                                            text: 'Seconds',
                                        },
                                        stacked: true,
                                    },
                                },
                            };
                            return (
                                <TabPanel
                                    m={0}
                                    p={0}
                                    h="48rem"
                                    key={b.channelId ?? ''}
                                    display="block"
                                >
                                    <>
                                        <Box>
                                            <Text
                                                textAlign="center"
                                                color="gray.600"
                                                fontWeight={600}
                                            >
                                                {b.channelName}
                                            </Text>
                                        </Box>
                                        {b.byAgeGroup?.some(
                                            (d) =>
                                                d.averageActiveAttention ||
                                                d.averagePassiveAttention,
                                        ) ||
                                        b.byGender?.some(
                                            (d) =>
                                                d.averageActiveAttention ||
                                                d.averagePassiveAttention,
                                        ) ? (
                                            <Flex
                                                gap="3rem"
                                                alignItems="baseline"
                                                justifyContent="space-between"
                                            >
                                                <Box h="24rem" flex="1 1 0">
                                                    <Bar
                                                        data={genderGraphData}
                                                        options={genderGraphOptions}
                                                    />
                                                </Box>
                                                <Box h="24rem" flex="1 1 0">
                                                    <Bar
                                                        data={ageGroupGraphData}
                                                        options={ageGroupGraphOptions}
                                                    />
                                                </Box>
                                            </Flex>
                                        ) : (
                                            <Box
                                                height="4rem"
                                                bgColor="white"
                                                padding="1rem"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Text
                                                    color="gray.600"
                                                    fontSize="sm"
                                                    fontStyle="italic"
                                                >
                                                    Audience analysis is not yet available for this
                                                    channel.
                                                </Text>
                                            </Box>
                                        )}
                                    </>
                                </TabPanel>
                            );
                        })}
                    </TabPanels>
                </Tabs>
                {isLoadingBenchmarks && (
                    <Box
                        position="absolute"
                        inset={0}
                        bg="white"
                        opacity={1}
                        display="grid"
                        placeItems="center"
                    >
                        <Spinner size="xl" speed=".6s" />
                    </Box>
                )}
            </Card>
            <Box opacity={0} position="absolute" zIndex={-999} pointerEvents="none" inset={0}>
                <Box width="36rem" height="30rem">
                    <Bar
                        ref={chartRef}
                        data={{
                            labels: [],
                            datasets: [],
                        }}
                    />
                </Box>
            </Box>

            {!isLoadingBenchmarks && (
                <Box mt="1rem">
                    <StrategyReportNavigation
                        next={`../${routing.strategyReports.view.creativeRecommendations.path}`}
                        prev={`../${routing.strategyReports.view.formatSelection.path}`}
                    />
                </Box>
            )}
        </Box>
    );
};
