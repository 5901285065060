import {
    Button,
    Center,
    HStack,
    Image,
    Link,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
    UnorderedList,
    useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { routing } from '@configs';
import { GetFullVersionModal } from '../getFullVersionModal/GetFullVersionModal';

export interface ProveAnnouncementModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const AnnouncementModal: FC<ProveAnnouncementModalProps> = ({ isOpen, onClose }) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const navigate = useNavigate();

    const {
        onOpen: onOpenFullVersionModal,
        onClose: onCloseFullVersionModal,
        isOpen: isFullVersionModalOpen,
    } = useDisclosure();

    return (
        <>
            <Modal size="4xl" isOpen={isOpen} onClose={handleClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader>New channels added</ModalHeader>
                    <ModalBody>
                        <Stack>
                            <Text>
                                OOH, Pinterest, Spotify, Snapchat AR lens, General web Desktop are
                                now available in Attention benchmarks and Strategy report.
                            </Text>
                            <Image
                                marginBottom="1rem"
                                marginX="10rem"
                                src="/benchmarks.png"
                                alt="strategy report example"
                            />
                            <Text>See how you can utilise these formats in your media plan!</Text>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button
                                variant="outline"
                                type="button"
                                onClick={() => {
                                    handleClose();
                                    onOpenFullVersionModal();
                                }}
                            >
                                Buy attentionPLAN
                            </Button>
                            <Button
                                variant="solid"
                                onClick={() => {
                                    handleClose();
                                    navigate(`/benchmarks`);
                                }}
                            >
                                View benchmarks
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <GetFullVersionModal
                isOpen={isFullVersionModalOpen}
                onClose={onCloseFullVersionModal}
            />
        </>
    );
};
